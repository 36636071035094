<template>
  <div id="cart-item-list">
    <div class="cart-item-container">
      <cart-item
        v-for="item in productItems"
        :key="item.id"
        :item="item"
        :dashed="dashed"
        class="cart-item"
      />
    </div>

    <div v-if="promoCodes.length">
      <br>
      <div class="promo-code-item-container">
        <cart-item
          v-for="item in promoCodes"
          :key="item.id"
          :item="item"
          :dashed="dashed"
          class="promo-code-item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartItemList',
  components: {
    CartItem: () => import('@/components/HireUs/CartItem'),
  },
  props: {
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'productItems',
      'promoCodes',
    ]),
  },
}
</script>

<style lang="scss" scoped>
#cart-item-list {
  justify-content: center;

  .cart-item-container, .promo-code-item-container {
    margin: auto -0.625em;
    padding-left: 0.625em;
  }

  .promo-code-item-container {
    background: rgba(76, 175, 80, 0.075);
    border-radius: 0.25em;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    padding-top: 0.625em;
    padding-bottom: 0.625em;
  }
}
</style>
