var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "cart-item-list" } }, [
    _c(
      "div",
      { staticClass: "cart-item-container" },
      _vm._l(_vm.productItems, function (item) {
        return _c("cart-item", {
          key: item.id,
          staticClass: "cart-item",
          attrs: { item: item, dashed: _vm.dashed },
        })
      }),
      1
    ),
    _vm.promoCodes.length
      ? _c("div", [
          _c("br"),
          _c(
            "div",
            { staticClass: "promo-code-item-container" },
            _vm._l(_vm.promoCodes, function (item) {
              return _c("cart-item", {
                key: item.id,
                staticClass: "promo-code-item",
                attrs: { item: item, dashed: _vm.dashed },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }